<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    :title="id ? '编辑榜单页' :'创建榜单页'"
    width="700"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :footer-style="{ textAlign: 'right' }"
  >
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
 
      <!-- 活动标题 -->
      <a-form-item
        label="榜单主题"
        name="name"
        :rules="[{ required: true, message: '榜单主题' }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.name"
        />
      </a-form-item>

      <!-- banner -->
      <a-form-item
        label="banner"
        name="banner_urls"
      >
        <Upload
          accept=".png,.jpg,.gif"
          uploadSource="NewFilmDrawer"
          :fileNumber="5"
          :imgSizeMode="3"
          :imgSizeWidth="670"
          :imgSizeHeight="332"
          imgSizeError="图片尺寸为 670 * 332"
          :beforeUploadPro="(file) => { return beforeUploadPro(file, 'banner_urls') }"
          :fileList="formState.banner_urls"
          :uploadBucket="$pub.BUCKET_PUB()"
        ></Upload>
        <div class="form-upload-hint">支持.png .jpg .gif，尺寸为 670x332。二级页面会调用该图片</div>
        <div class="form-upload-list" v-if="formState.banner_urls.length">
          <div
            class="form-upload-item"
            v-for="(item, index) in formState.banner_urls"
            :key="index"
          >
            <ImageView
              class="upload-image upload-image-horizontal"
              :url="$pub.CDN_URL(item.url)"
              :fileJson="item"
              @delete="touchDeleteCover('banner_urls', index)"
            >
            </ImageView>
          </div>
        </div>
      </a-form-item>

      <!-- 短剧 -->
      <a-form-item
        label="内容"
        :rules="[{ required: true }]"
      >
        <!-- 单条数据 -->
        <a-form-item
          class="play-item"
          v-for="(play, index) in formState.drama_info"
          :key="index"
          :rules="[{ required: true, message: '请选择内容' }]"
          :name="['drama_info', index, 'id']"
        >
          <a-select
            style="width: 300px"
            show-search
            placeholder="请选择"
            v-model:value="play.id"
            :filter-option="filterOption"
            @change="changeDrama(index, $event)"
          >
            <a-select-option
              v-for="drama in dramas"
              :key="`${drama.id}-${drama.remark_name}`"
              :value="drama.id"
            >
              {{ drama.remark_name }}
            </a-select-option>
          </a-select>
          <!-- 第一组不允许上移 -->
          <a-button
            type="link"
            :disabled="index === 0"
            @click="move(index, 'up')"
          >
            上移
          </a-button>
          <!-- 最后一组不允许下移 -->
          <a-button
            type="link"
            :disabled="index === formState.drama_info.length - 1"
            @click="move(index, 'down')"
          >
            下移
          </a-button>
          <!-- 唯一一组不允许删除 -->
          <a-button
            type="link"
            :disabled="formState.drama_info.length <= 10"
            @click="deletePlay(index)"
          >
            删除
          </a-button>
        </a-form-item>
      </a-form-item>
      <div style="margin: -20px 0 0 112px;">
        <a @click="addPlay">新增位置</a>
      </div>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, defineExpose, nextTick } from 'vue'
import { message } from 'ant-design-vue'
import Upload from '@/components/Upload'
import Loading from '@/components/Loading'
import ImageView from '@/components/ImageView'
import { rankingSave, dramaAll } from '@/api/copyright'
import Pub from '@/utils/public'
import dayjs from 'dayjs'

// emit
const emit = defineEmits(['success'])
// Form实例
const RefForm = ref(null)
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// id
let id = ref(null)
// 详情
let detail = ref({})
// 短剧列表
let dramas = ref([])
// 表单
let formState = reactive({
  // rankingSave
  name: undefined,
  // banner图
  banner_urls: [],
  banner_url: undefined,
  //短剧ids
  drama_info: [{
    name: undefined,
    id: undefined
  },{
    name: undefined,
    id: undefined
  },{
    name: undefined,
    id: undefined
  },{
    name: undefined,
    id: undefined
  },{
    name: undefined,
    id: undefined
  },{
    name: undefined,
    id: undefined
  },{
    name: undefined,
    id: undefined
  },{
    name: undefined,
    id: undefined
  },{
    name: undefined,
    id: undefined
  },{
    name: undefined,
    id: undefined
  }]
  
})

// 打开抽屉
function showDrawer (params) {
  console.log(params)
  getDramaAll()
  // 展开
  detail.value = params
  visible.value = true
  // 赋值
  nextTick(() => {
    if (params) {
      // id
      id.value = params.id
      formState.name = params.name
      if (params.banner_url) {
        formState.banner_url = params.banner_url
        formState.banner_urls = [{
          url: params.banner_url
        }]
      } else {
        formState.banner_url = ''
        formState.banner_urls = []
      }
      if (params.drama_info && params.drama_info.length > 0) {
        formState.banner_url = params.banner_url
        const drama_info = []
        for (const index in params.drama_info) {
          const item = params.drama_info[index]
          drama_info.push({ ...item })
        }
        formState.drama_info = drama_info
      }
    }
  })
}

// 短剧列表
function getDramaAll () {
  dramaAll().then(res => {
    const { code, data } = res
    if (code === 0) {
      dramas.value = data
    }
  })
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    // 重置
    RefForm.value.resetFields()
    id.value = undefined
    visible.value = false
  }
}

// 准备上传（图片）
function beforeUploadPro (file, key) {
  if (!file.type.includes('image')) {
    message.error('请上传正确的图片类型文件')
    return false
  }
  // 清空列表
  if (key) { formState[key] = [] }
  // 允许
  return true
}

// 移除封面
function touchDeleteCover (coverKey, index) {
  formState[coverKey].splice(index, 1)
}

// 删除短剧
function deletePlay (index) {
  formState.drama_info.splice(index, 1)
}

// 移动短剧
function move (index, type) {
  // 保存当前项
  const temp = formState.drama_info[index]
  if (type === 'up') {
    // 处理上移
    formState.drama_info[index] = formState.drama_info[index - 1]
    formState.drama_info[index - 1] = temp
  } else {
    // 处理下移
    formState.drama_info[index] = formState.drama_info[index + 1]
    formState.drama_info[index + 1] = temp
  }
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then(() => {
    isLoading.value = true
    if (formState.banner_urls.length > 0) {
      formState.banner_url = formState.banner_urls[0].url
    } else {
      formState.banner_url = ''
    }
    const paramInfos = {
      id: id.value,
      name: formState.name,
      title: formState.title,
      banner_url: formState.banner_url,
      drama_info: formState.drama_info,
    }
    rankingSave(paramInfos).then(res => {
      isLoading.value = false
      const { code, msg, data } = res
      if (code === 0) {
        if (id.value) {
          message.success('更新成功')
        } else {
          message.success('创建成功')
        }
        onClose()
        // 更新父组件
        emit('success')
      } else {
        message.error(res.message || msg)
      }
    })
  })
}

// 模糊匹配
function filterOption (input, option) {
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 修改短剧选中：把选中剧的 name 塞进 formState.drama_info 中
function changeDrama (index, e) {
  formState.drama_info[index].name = dramas.value.filter(item => item.id === e)[0].name
}

// 新增短剧位
function addPlay () {
  formState.drama_info.push({
    name: undefined,
    id: undefined
  })
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.a-link {
  margin-left: 8px;
}
.form-item-clear {
  margin-bottom: 0;
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.image-view.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  position: relative;
  width: 118px;
  height: 148px;
  border-radius: 4px;
  overflow: hidden;
  img {
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.horizontal {
    width: 148px;
    height: 118px;
  }
}
.upload-item-select {
  position: absolute;
  right: 8px;
  bottom: 8px;
}
.upload-item-delete {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 18px;
  color: #8D8D8D;
  cursor: pointer;
  opacity: 0;
}
.form-upload-item:hover .upload-item-delete {
  opacity: 1;
}
.date-range {
  color: #8D8D8D;
  padding-left: 100px;
  margin-top: -15px;
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  width: 118px;
}
.image-view.upload-image {
  height: 148px;
}
.image-view.upload-image-horizontal {
  width: 208px;
  height: 124px;
}
.desc-textarea {
  word-wrap: break-word;
  white-space: normal;
  word-break: break-all;
}

.drama-menu {
  display: flex;
  padding: 6px 0 0 6px;
  flex-wrap: wrap;
  .ant-form-item {
    margin-right: 6px;
  }
}


.play-item {
  margin-bottom: 16px;
  .ant-btn-link {
    padding: 0;
    margin-left: 8px;
  } 
}
</style>